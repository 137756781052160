import React, { useEffect, useState } from 'react';
import CommonLayout from '../../layout/CommonLayout';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import AttestationLeftMenu from './AttestationLeftMenu';
// import { Survey } from 'survey-react-ui';
import DeleteButton from '../../common/DeleteButton';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  checkUserPermission,
  getSelectedAccountInfo,
  useAuthToken,
  useCurrentUserInfo,
  decryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import { Button, CircularProgress, SelectChangeEvent } from '@mui/material';
import {
  CREATE,
  DELETE,
  MODULES,
  NOTE_CREATE,
  READ,
  TASK_CREATE,
  UPDATE
} from '../../../../constants/constants';
import Popup from '../../common/popup/Popup';
import { AttestationState, AttestationTemplatesState } from './state';
import { uploadSurveyFile } from '../../../../api/survey/uploadSurveyFile';
import { Model } from 'survey-core';
import * as SurveyCore from 'survey-core';
import { fetchAttestationTemplate } from '../../../../api/attestations/fetchAttestationTemplate';
import { useNotificationContext } from '../../layout/NotificationContext';
import {
  createEditAssestationData,
  sendEmail
} from '../../../../api/attestations/createEditAssestationData';
import { fetchAttestations } from '../../../../api/attestations/fetchAttestations';
import AttestationTemplateTextMode from './AttestationTemplateTextMode';
import { deleteAttestation } from '../../../../api/attestations/deleteAttestation';
import AttestationCreateEditFormData from './AttestationCreateEditFormData';
import { DropdownResponse } from '../../user/state';
import { fetchActiveUsers } from '../../../../api/common/fetchActiveUsers';
import { fetchRelevantPeriods } from '../../../../api/common/fetchRelevantPeriods';
import dayjs from 'dayjs';
import { fetchManagerUsers } from '../../../../api/common/fetchManagerUsers';

const AttestationCreateEdit = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  const accountId = selectedAccount.id;
  const user = useCurrentUserInfo();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const { pathname, search } = useLocation();
  const current_route = pathname;
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const { moduleRecords } = useModuleContext();
  const { setNotificationSuccess } = useNotificationContext();
  const [loading, setLoading] = useState<boolean>(false);

  let secondTitle;
  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    secondTitle = foundRegister ? foundRegister?.name : '';
  }

  const mainPath = `/attestations/${encryptedSourceId}`;
  const pageTitle = id ? `Attestation Details` : `Create Attestation`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [survey, setSurvey] = useState<any>('');
  const [templateData, setTemplateData] = useState<string>('');
  const [templateId, setTemplateId] = useState<number>(0);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [periods, setPeriods] = useState<DropdownResponse[]>([]);
  const [owners, setOwners] = useState<DropdownResponse[]>([]);
  const [users, setUsers] = useState<DropdownResponse[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [userIds, setUserIds] = useState<number[]>([]);
  const [formData, setFormData] = useState<AttestationState>({
    id: 0,
    owner_id: 0,
    user_id: 0,
    attestation_template_id: 0,
    relevant_period: '',
    due_date: '',
    instructions: '',
    is_open: 1
  });
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);

  useEffect(() => {
    const permission =
      current_route === `/attestation/${encryptedId}` ? READ : id ? UPDATE : CREATE;
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  SurveyCore.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    options.request.setRequestHeader('Authorization', 'Bearer ' + authToken);
  };

  const fetchTemplate = async () => {
    try {
      const recordId = parseInt(id!, 10);
      if (sourceId && authToken) {
        const response: AttestationTemplatesState = (await fetchAttestationTemplate(
          source_Id,
          accountId,
          authToken,
          recordId
        )) as AttestationTemplatesState;

        if (response) {
          const { id, form_data } = response;
          if (form_data && form_data !== '""') {
            setTemplateData(form_data);
            setTemplateId(id);
            localStorage.setItem('survey-json-attestation-template', form_data);
          } else {
            localStorage.removeItem('survey-json-attestation-template');
            setFormData((prevData) => ({ ...prevData, error: t('error.field.notemplateFound') }));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    }
  };

  const fetchData = async () => {
    if (id && authToken) {
      try {
        const editId = parseInt(id!, 10);

        const response: AttestationState = (await fetchAttestations(
          authToken,
          selectedAccount,
          editId
        )) as AttestationState;

        if (response) {
          const {
            id,
            unique_id,
            attestation_template_id,
            relevant_period,
            due_date,
            instructions,
            owner_id,
            user_id,
            is_open,
            attestation_data,
            owner,
            user,
            created_at
          } = response;

          const newFormData = {
            id,
            unique_id,
            attestation_template_id,
            relevant_period,
            due_date,
            instructions,
            owner_id,
            user_id,
            is_open,
            attestation_data,
            owner,
            user,
            created_at
          };
          setFormData(newFormData);
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  const fetchPeriods = async () => {
    if (
      current_route === `/attestation-add` ||
      current_route === `/attestation-edit/${encryptedId}`
    ) {
      try {
        const response: DropdownResponse[] = await fetchRelevantPeriods(authToken, selectedAccount);
        if (response) {
          setPeriods(response);
        }
      } catch (error) {
        setErrors({});
        console.error('Error fetching templates:', error);
      }
    }
  };

  const fetchOwners = async () => {
    if (
      current_route === `/attestation-add` ||
      current_route === `/attestation-edit/${encryptedId}`
    ) {
      try {
        const response: DropdownResponse[] = await fetchManagerUsers(
          authToken,
          selectedAccount
        );
        if (response) {
          setOwners(response);
        }
      } catch (error) {
        setErrors({});
        console.error('Error fetching templates:', error);
      }
    }
  };

  const fetchUsers = async () => {
    if (
      current_route === `/attestation-add` ||
      current_route === `/attestion-edit/${encryptedId}`
    ) {
      try {
        const response: DropdownResponse[] = await fetchActiveUsers(authToken, selectedAccount);
        if (response) {
          setUsers(response);
        }
      } catch (error) {
        setErrors({});
        console.error('Error fetching templates:', error);
      }
    }
  };

  useEffect(() => {
    if (
      Object.keys(templateData).length !== 0 &&
      Object.keys(templateData).length > 1 &&
      templateData != '""' &&
      formData?.attestation_data &&
      Object.keys(formData?.attestation_data?.form_data).length !== 0
    ) {
      const mergedResponse = mergeFieldValues(
        JSON.parse(templateData),
        JSON.parse(formData.attestation_data.form_data)
      );
      localStorage.setItem('survey-json-attestation-template', JSON.stringify(mergedResponse));
    } else if (Object.keys(templateData).length !== 0 && Object.keys(templateData).length > 1) {
      localStorage.setItem('survey-json-attestation-template', templateData);
    }

    if (!hasEffectRun) {
      SurveyCore.ChoicesRestfull.clearCache();

      fetchTemplate();
      fetchData();
      fetchPeriods();
      fetchOwners();
      fetchUsers();
      setHasEffectRun(true);
    }

    setSurveyData();
  }, [templateData, formData, hasEffectRun, current_route]);

  useEffect(() => {
    if (survey && survey.data) {
      // Dynamic handling for signature pad questions
      survey.onValueChanged.add((sender: any) => {
        survey.getAllQuestions().forEach(function (question: any) {
          if (question.jsonObj.type === 'signaturepad') {
            const visibleIfCondition = question.visibleIf;
            if (visibleIfCondition) {
              // Evaluate the visibleIf condition
              const dependentQuestionName = visibleIfCondition.split(' ')[0].replace(/[{}]/g, '');
              const dependentQuestionValue = visibleIfCondition.split(' ')[2].replace(/[{}]/g, '');

              // Find the dependent question by name
              const dependentQuestion = sender.getQuestionByName(dependentQuestionName);

              if (dependentQuestion) {
                // Check the value of the dependent question
                const isConditionMet =
                  dependentQuestion.value.toString() === dependentQuestionValue.toString();

                question.visible = isConditionMet;
                // Clear signature pad if not visible
                if (!isConditionMet) {
                  question.canvas = '';
                }
              }
            }
          }
        });
      });

      survey.onUploadFiles.add((_: any, options: any) => {
        try {
          uploadSurveyFile(options.files, authToken)
            .then((response) => {
              const data: any = response;
              setTimeout(() => {
                options.callback(
                  options.files.map((file: any, index: number) => {
                    return {
                      file: file,
                      content: data[index]
                    };
                  })
                );
              }, 2000);
            })
            .catch((error) => {
              // Handle other error cases
              console.error(error); // Log the error
              options.callback([], ['An error occurred during file upload.']);
            });
        } catch (error) {
          // Handle other error cases
          console.error(error); // Log the error
          options.callback([], ['An error occurred during file upload.']);
        }
      });

      // Save survey results when click on next
      survey.onCurrentPageChanged.add(() => {
        handleSurveySubmit('nextPage');
      });

      survey.onComplete.add(() => {
        handleSurveySubmit();
      });
    }
  }, [survey]);

  const mergeFieldValues = (
    mainResponse: any, // Add explicit type
    fieldValues: any // Add explicit type
  ) => {
    mainResponse.pages.forEach((page: any) => {
      page.elements.forEach((element: any) => {
        const fieldName = element.name;
        if (fieldValues[fieldName] !== undefined) {
          // Add the value to the element
          element.defaultValue = fieldValues[fieldName];
        }
      });
    });
    return mainResponse;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (date: dayjs.Dayjs | null): void => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : '';
    setFormData((prevData) => ({ ...prevData, due_date: formattedDate }));
  };

  const handleSelectChange = (event: SelectChangeEvent<string | string[]>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setSelectAll(checked);
    if (checked) {
      const idArray = users.map((item) => item.id);
      setUserIds(idArray);
    } else {
      setUserIds([]);
    }
  };

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const checked = event.target.checked;
    if (checked) {
      const idArray = [...userIds, id];
      setUserIds(idArray);

      if (users.length === idArray.length) {
        setSelectAll(true);
      }
    } else {
      const idArray = userIds.filter((userId) => userId !== id);
      setUserIds(idArray);

      if (users.length !== idArray.length) {
        setSelectAll(false);
      }
    }
  };

  const setSurveyData = () => {
    const surveyJson = localStorage.getItem('survey-json-attestation-template') || ''; // Provide an empty string as the default

    if (surveyJson !== '' && surveyJson !== 'undefined' && surveyJson !== '""') {
      const surveyModel = new Model(surveyJson);
      surveyModel.showCompletedPage = false; // prevent the thank-you page
      surveyModel.showNavigationButtons = true; // Hide the default navigation buttons
      surveyModel.isSinglePage = false; // show all pages in single
      surveyModel.focusFirstQuestionAutomatic = false;
      surveyModel.pageNextText = 'Save and next';
      surveyModel.completeText = 'Save';
      surveyModel.addNavigationItem({
        id: 'survey_clear_current_page',
        title: 'Cancel',
        visibleIndex: 15, // The "Complete" button has the visibleIndex 50.
        action: () => {
          handleCancelClick();
        }
      });

      setSurvey(surveyModel);
    }
  };

  const handleCancelClick = () => {
    if (id) {
      navigate(`/attestation/${encryptedId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/attestations/${encryptedSourceId}`);
    }
  };
  const editBtnRedirect = () => {
    navigate(`/attestation-form?source=${encryptedSourceId}&attestationId=${encryptedId}`);
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.relevant_period) {
      newErrors.relevant_period = t('error.field.relevantPeriod');
    }

    if (!formData.due_date) {
      newErrors.due_date = t('error.field.dueDate');
    }

    if (!formData.owner_id) {
      newErrors.owner_id = t('error.field.ownerRequired');
    }
    if (userIds.length === 0) {
      newErrors.user_id = t('error.field.recipientsRequired');
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (newStatus: number) => {
    let isFormValid;
    if (newStatus == 0) {
      isFormValid = true;
    } else {
      isFormValid = validateForm();
    }
    if (isFormValid) {
      setLoading(true);

      const requestData = {
        relevant_period: formData.relevant_period,
        due_date: formData.due_date,
        owner_id: formData.owner_id,
        instructions: formData.instructions,
        user_ids: userIds,
        is_open: formData.id && newStatus == 0 ? '0' : formData.is_open,
        attestation_template_id: formData.attestation_template_id
          ? formData.attestation_template_id
          : templateId
      };

      const recordEditId = localStorage.getItem('attestation-id');
      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createEditAssestationData(
          requestData,
          authToken,
          selectedAccount,
          source_Id,
          recordEditId ? recordEditId : edit_id
        );
        if (response && response.id) {
          localStorage.removeItem('survey-json-attestation-template');
          if (!id) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const responseData: any = await sendEmail(
              response.recordIds,
              source_Id,
              authToken,
              selectedAccount
            );
          }
          const msg = id
            ? t('success.field.attestationUpdated')
            : t('success.field.attestationCreated');
          // localStorage.setItem('attestation-id', id ? id : response?.id);
          // localStorage.removeItem('attestation-id');
          setHasEffectRun(false);
          setNotificationSuccess(msg);
          navigate(`/attestations/${encryptedSourceId}`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log('Error', response);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSurveySubmit = async (type = '') => {
    let validationResult = true;

    if (templateData && !type) {
      validationResult = survey.validate();
    }
    if (!validationResult) {
      return;
    }

    if (validationResult) {
      const surveyData = survey.data;
      surveyData.pageNo = survey.currentPageNo;

      const requestData = {
        relevant_period: survey && survey?.data.Period ? survey?.data.Period : '',
        due_date: survey && survey?.data.due_date ? survey?.data.due_date : '',
        owner_id: survey && survey?.data.Owner ? survey?.data.Owner : '',
        instructions: survey && survey?.data.Instructions ? survey?.data.Instructions : '',
        user_ids: survey && survey?.data.User ? survey?.data.User : '',
        is_open: survey && survey?.data.Status ? survey?.data.Status : 1,
        attestation_template_id: formData.attestation_template_id
          ? formData.attestation_template_id
          : templateId,
        form_data: survey ? surveyData : ''
      };

      const recordEditId = localStorage.getItem('attestation-id');
      const edit_id = id ? id : formData.id;
      try {
        const response: any = await createEditAssestationData(
          requestData,
          authToken,
          selectedAccount,
          source_Id,
          recordEditId ? recordEditId : edit_id
        );
        if (!response) {
          localStorage.removeItem('survey-json-attestation-template');
          const msg = id
            ? t('success.field.attestationUpdated')
            : t('success.field.attestationCreated');
          localStorage.setItem('attestation-id', id ? id : response?.id);
          if (!type) {
            localStorage.removeItem('attestation-id');
            setHasEffectRun(false);
            setNotificationSuccess(msg);
            navigate(`/attestations/${encryptedSourceId}`);
          }
        } else {
          console.log('Error', response);
        }
      } catch (error) {
        // Handle other error cases
        console.error(error); // Log the error
        // You can handle other error cases as needed
      }
    }
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (id && authToken) {
        const response: any = (await deleteAttestation(parseInt(id!, 10), authToken)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.attestationDeleted'));
          navigate(`/attestations/${encryptedSourceId}`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  const edit_btn_snapshot = null;
  let edit_btn_form = null;

  let addButton;
  if (formData && formData.is_open > 0) {
    edit_btn_form = (
      <>
        {checkUserPermission(user, MODULES, parseInt(sourceId!, 10), UPDATE) &&
          formData.user_id === user?.id && (
            <Button onClick={() => editBtnRedirect()} className="btn blue-fill-btn">
              <i className="left">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                  <g clipPath="url(#clip0_6545_879)">
                    <path
                      d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                      stroke="CurrentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6545_879">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              Edit
            </Button>
          )}
      </>
    );
    addButton = (
      <div className="inner-btn-wrap">
        {current_route === `/attestation/${encryptedId}` && (
          <>
            {checkUserPermission(user, MODULES, source_Id, NOTE_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/attestation/add-note?source=${encryptedSourceId}&attestationId=${encryptedId}`}>
                <i className="left">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Note
              </Button>
            )}
            {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/attestation/add-task?source=${encryptedSourceId}&attestationId=${encryptedId}`}>
                <i className="left">
                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M11.3333 3.33268C12.1082 3.33268 12.4957 3.33268 12.8136 3.41787C13.6764 3.64904 14.3502 4.3229 14.5814 5.18564C14.6666 5.50355 14.6666 5.89104 14.6666 6.66602V14.3327C14.6666 15.7328 14.6666 16.4329 14.3941 16.9677C14.1544 17.4381 13.772 17.8205 13.3016 18.0602C12.7668 18.3327 12.0667 18.3327 10.6666 18.3327H5.33325C3.93312 18.3327 3.23305 18.3327 2.69828 18.0602C2.22787 17.8205 1.84542 17.4381 1.60574 16.9677C1.33325 16.4329 1.33325 15.7328 1.33325 14.3327V6.66602C1.33325 5.89104 1.33325 5.50355 1.41844 5.18564C1.64961 4.3229 2.32347 3.64904 3.1862 3.41787C3.50412 3.33268 3.89161 3.33268 4.66659 3.33268M5.49992 12.4993L7.16659 14.166L10.9166 10.416M5.99992 4.99935H9.99992C10.4666 4.99935 10.7 4.99935 10.8782 4.90852C11.035 4.82863 11.1625 4.70114 11.2424 4.54434C11.3333 4.36608 11.3333 4.13273 11.3333 3.66602V2.99935C11.3333 2.53264 11.3333 2.29928 11.2424 2.12102C11.1625 1.96422 11.035 1.83674 10.8782 1.75684C10.7 1.66602 10.4666 1.66602 9.99992 1.66602H5.99992C5.53321 1.66602 5.29985 1.66602 5.12159 1.75684C4.96479 1.83674 4.83731 1.96422 4.75741 2.12102C4.66659 2.29928 4.66659 2.53264 4.66659 2.99935V3.66602C4.66659 4.13273 4.66659 4.36608 4.75741 4.54434C4.83731 4.70114 4.96479 4.82863 5.12159 4.90852C5.29985 4.99935 5.53321 4.99935 5.99992 4.99935Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Task
              </Button>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={formData.unique_id ? formData.unique_id : pageTitle}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                errorMsg={formData.error}
                button={id ? addButton : undefined}
              />
              <div className="record-outer-row settings-outer-row">
                <AttestationLeftMenu
                  user={user}
                  attestationId={id}
                  sourceId={sourceId ? sourceId : ''}
                  formDataRecord={formData}
                  attestationIdEncrypted={encryptedId}
                  sourceIdEncrypted={encryptedSourceId}
                />

                <div className="right-outer">
                  <div className="right-inner">
                    {(current_route === `/attestation-add` ||
                      current_route === `/attestation-edit/${encryptedId}` ||
                      current_route === `/attestation/${encryptedId}`) && (
                      <AttestationCreateEditFormData
                        formData={formData}
                        periods={periods}
                        owners={owners}
                        users={users}
                        errors={errors}
                        current_route={current_route}
                        edit_btn_snapshot={edit_btn_snapshot}
                        selectAll={selectAll}
                        userIds={userIds}
                        templateData={templateData}
                        sourceId={source_Id}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        handleSelectAll={handleSelectAll}
                        handleUserChange={handleUserChange}
                        handleFormSubmit={handleFormSubmit}
                        handleDateChange={handleDateChange}
                        attestationIdEncrypted={encryptedId}
                        sourceIdEncrypted={encryptedSourceId}
                      />
                    )}

                    {(current_route === `/attestation-add` ||
                      current_route === `/attestation-edit/${encryptedId}`) && (
                      <div className="btn-wrap">
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                              Cancel
                            </Button>
                            {templateData && (
                              <Button
                                className="btn primary-btn btn-sm"
                                type="submit"
                                onClick={() => handleFormSubmit(1)}>
                                Send attestations to users
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    {templateData && current_route === `/attestation/${encryptedId}` && (
                      <div className="card-outline">
                        <AttestationTemplateTextMode
                          surveyData={JSON.parse(templateData)}
                          formData={
                            formData?.attestation_data?.form_data
                              ? JSON.parse(formData?.attestation_data?.form_data)
                              : {}
                          }
                          edit_btn={edit_btn_form}
                        />
                      </div>
                    )}

                    {current_route === `/attestation/${encryptedId}` &&
                      checkUserPermission(user, MODULES, source_Id, DELETE) && (
                        <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to delete this record?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default AttestationCreateEdit;
