import axios from 'axios';

interface LoginFormState {
  email: string;
}

export const sendResetPasswordEmail = async (
  formData: LoginFormState,
  type?: string
): Promise<string | undefined> => {
  const { email } = formData;

  try {
    const url =
    type === 'onboardingUser'
      ? `${process.env.REACT_APP_API_URL}/password/email?type=${type}`
      : `${process.env.REACT_APP_API_URL}/password/email-outseta?type=${type}`;

  // Send the request
  await axios.post(url, { email });

    return;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message;

    return errorMessage;
  }
};
