import React, { useState } from 'react';
import CommonLayout from './layout/CommonLayout';
import CommonBreadcrumbs from './layout/Breadcrumb/CommonBreadcrumbs';
import { CircularProgress } from '@mui/material';

const AFSLChat: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const title: string = 'AFSL Chat';
  const metapageTitle: string = `${process.env.REACT_APP_NAME || 'App Name'}: ${title}`;
  const mainPath: string = '/afsl-chat';
  const secondTitle: string = 'Dashboard';
  const secondpath: string = '/dashboard';
  const pageTitle: string = title;

  return (
    <CommonLayout title={metapageTitle}>
      <section className="record-section btm-pad-0">
        <div className="container-full">
          <CommonBreadcrumbs
            mainPath={mainPath}
            title={title}
            pageTitle={pageTitle}
            secondTitle={secondTitle}
            secondpath={secondpath}
          />
          <div style={{ position: 'relative', width: '100%' }}>
            {loading && (
              <div className="no-record">
                <CircularProgress />
              </div>
            )}{' '}
            {/* Display a loading message */}
            <iframe
              src="http://18.212.90.44:8501/"
              title="Streamlit App"
              style={{
                width: '100%',
                height: '63vh',
                border: 'none'
              }}
              onLoad={() => setLoading(false)} // Set loading to false after iframe loads
            ></iframe>
          </div>
        </div>
      </section>
    </CommonLayout>
  );
};

export default AFSLChat;
