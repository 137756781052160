import React, { useState, useEffect } from 'react';
import { AppBar, Box, Toolbar, Typography, IconButton, Link } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLocation } from 'react-router';

interface AppBarElementProps {
  onVisibilityChange?: (isVisible: boolean) => void;
}

export default function AppBarElement({ onVisibilityChange }: AppBarElementProps) {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(() => {
    const storedVisibility = localStorage.getItem('appBarVisible');
    return storedVisibility !== 'false'; // Default to true if not set
  });

  useEffect(() => {
    onVisibilityChange?.(isVisible);
  }, [isVisible, onVisibilityChange]);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('appBarVisible', 'false'); // Persist visibility state
  };

  // Check if the current path is `/dashboard`
  if (location.pathname !== '/dashboard' || !isVisible) {
    return null; // Render nothing if not on `/dashboard` or not visible
  }

  return (
    <Box sx={{ flexGrow: 1, marginBottom: '10px' }}>
      <AppBar
        position="static"
        elevation={0} // Removes Material-UI default shadow
        sx={{
          boxShadow: 'none',
          backgroundColor: '#16D299', // Professional green background
          color: 'white', // White text for better contrast
          padding: '10px 20px' // Adds padding for a clean layout
        }}>
        <Toolbar
          variant="dense"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px'
          }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontSize: '1.2rem',
              fontWeight: 'normal',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
            FREE training module for 3Lines subscribers - &#39;CCMs SIMPLIFIED: the what, why and how!&#39;
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
              fontSize: '1.2rem'
            }}>
            <Link
              href="https://www.3lines.com.au/rmbootcamp"
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
              sx={{
                color: 'white',
                textDecoration: 'none',
                transition: 'color 0.3s ease',
                '&:hover': { color: '#FFD700' },
                paddingRight:'1rem'
              }}>
              Watch NOW
            </Link>
            {/* <span style={{ color: 'white', fontWeight: 'bold' }}>|</span>
            <Link
              href="https://www.3lines.com.au/offers/W7RV7vMd?coupon_code=RM50NOW"
              target="_blank"
              rel="noopener noreferrer"
              underline="hover"
              sx={{
                color: 'white',
                textDecoration: 'none',
                transition: 'color 0.3s ease',
                '&:hover': { color: '#FFD700' }
              }}>
              Buy now!
            </Link> */}
          </Box>

          {/* Close Icon */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={handleClose}
            sx={{
              padding: '5px',
              transition: 'transform 0.3s ease',
              '&:hover': { transform: 'scale(1.2)' }
            }}>
            <HighlightOffIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
