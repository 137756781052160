import React, { useEffect, useState } from 'react';
import CommonLayout from '../../../layout/CommonLayout';
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  CircularProgress
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForgotPasswordContext } from '../../../user/ForgotPasswordContext';
import { LoginFormState } from '../../../user/state';
import { loginUser } from '../../../../../api/user/login';
import { S3PublicUrl } from '../../../../../constants/constants';
import LazyImage from '../../../common/LazyLoad';

const OnboardingLogin = () => {
  const title = 'Login';
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${title}`;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Get the dispatch function
  const [showAlert, setShowAlert] = useState(true);
  const { forgotPasswordSuccess, setForgotPasswordSuccess } = useForgotPasswordContext();
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  let successAlertTimer: ReturnType<typeof setTimeout>;
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState<LoginFormState>({
    email: '',
    password: '',
    passwordError: '',
    error: '',
    emailError: '',
    type: t('onboarding.user')
  });

  useEffect(() => {
    if (forgotPasswordSuccess) {
      setShowSuccessAlert(true);

      // Set a timer to close the alert after 5000 milliseconds (5 seconds)
      successAlertTimer = setTimeout(() => {
        setShowSuccessAlert(false);
        setForgotPasswordSuccess(false);
      }, 5000);
    }

    return () => {
      // Clear the timer when the component unmounts or when forgotPasswordSuccess changes
      clearTimeout(successAlertTimer);
    };
  }, [forgotPasswordSuccess, setForgotPasswordSuccess]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const validateForm = (): boolean => {
    const { email, password } = formData;

    let isValid = true;

    if (!email) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: t('user.emailRequired')
      }));
      isValid = false;
    } else if (email) {
      // Email pattern validation
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(email)) {
        setFormData((prevData) => ({
          ...prevData,
          emailError: t('user.enterValidEmail')
        }));

        isValid = false;
      } else {
        setFormData((prevData) => ({
          ...prevData,
          emailError: ''
        }));

        isValid = true;
      }
    }

    if (!password) {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: t('user.passwordRequired')
      }));
      isValid = false;
    } else {
      setFormData((prevData) => ({
        ...prevData,
        passwordError: ''
      }));
    }

    return isValid;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    setLoading(true);
    event.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid) {
      setLoading(false);
      return;
    }

    const errorMessage = await loginUser(dispatch, formData); // Pass the dispatch function

    if (errorMessage) {
      setLoading(false);
      setFormData((prevData) => ({
        ...prevData,
        emailError: '',
        passwordError: '',
        error: errorMessage
      }));
      setShowAlert(true);
    } else {
      setLoading(false);
      // No error, navigate to the AccountSelection component
      navigate(localStorage.getItem('redirectAfterLogin') || '');
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = (): void => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  return (
    <CommonLayout title={metapageTitle}>
      <div className="pre-login-wrapper">
        <div className="pre-login-inner">
          <div className="left-col">
            <div className="left-inner">
              {showSuccessAlert && (
                <Alert
                  variant="outlined"
                  severity="success"
                  onClose={() => setForgotPasswordSuccess(false)}>
                  Your password has been reset. Please login using your new password.
                </Alert>
              )}

              <div className="logo-wrap">
                <Link to="/">
                  <LazyImage src={`${S3PublicUrl}3lines-logo.svg`} alt="logo" />
                </Link>
              </div>
              <div className="title-wrap">
                <h2>{t('user.completeAccount')}</h2>
                <p>{t('user.enterDetail')}</p>
              </div>
              {/* Error handling */}
              {formData.error && showAlert && (
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleCloseAlert}>
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  {formData.error}
                </Alert>
              )}
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="form-group">
                  <label className="form-label" htmlFor="email">
                    {t('user.email')}
                  </label>
                  <TextField
                    error={!!formData.emailError || !!formData.passwordError}
                    id="email"
                    variant="outlined"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter your email"
                    helperText={formData.emailError}
                    name="email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InfoOutlinedIcon className="message-ic" />
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="password">
                    {t('user.password')}
                  </label>
                  <TextField
                    error={!!formData.emailError || !!formData.passwordError}
                    id="password"
                    variant="outlined"
                    name="password"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter your password"
                    helperText={formData.passwordError}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                {loading ? (
                  <div className="circular-progress-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Button variant="contained" type="submit" className="btn primary-btn mb-3">
                      {t('user.signIn')}
                    </Button>
                  </>
                )}
                <div className="checkbox-link-wrap">
                  <Link
                    to={`/reset-password?type=${t('onboarding.user')}`}
                    className="primary-link">
                    {t('user.forgotPassword')} ?
                  </Link>{' '}
                </div>
              </form>
            </div>
          </div>
          <div className="right-image-col">
            <div className="right-inner">
              <div className="img-block">
                <LazyImage
                  src={`${S3PublicUrl}3Lines-onboarding-login.png`}
                  alt=""
                  rootMargin="0px 0px 200px 0px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default OnboardingLogin;
