import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink, useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  useAuthToken,
  getSelectedAccountInfo,
  useCurrentUserInfo,
  checkUserPermission,
  decryptData,
  encryptData,
  isSubscriptionExpired
} from '../../../../utils/common/user';
import { useTranslation } from 'react-i18next';
import CommonBreadcrumbs from '../../layout/Breadcrumb/CommonBreadcrumbs';
import { useNotificationContext } from '../../layout/NotificationContext';
import CommonLayout from '../../layout/CommonLayout';
import { useModuleContext } from '../../settings/modules/ModulesContext';
import CalendarCreateEditFormData from './CalendarCreateEditFormData';
import { createCalendarData } from '../../../../api/calendar/createCalendarEvent';
import CalenderLeftMenu from './CalenderLeftMenu';
import { getCalendarEventId } from '../../../../api/calendar/getCalendarEvents';
import { CalendarEventData, CalendarFormData } from './state';
import { CalenderEventState, DropdownResponse } from '../../user/state';
import { RRule } from 'rrule';
import { mapFrequencyToString } from '../../../../utils/commonUtils';
import { format } from 'date-fns';
import {
  CREATE,
  DELETE,
  MODULES,
  NOTE_CREATE,
  READ,
  TASK_CREATE,
  UPDATE
} from '../../../../constants/constants';
import { fetchManagerUsers } from '../../../../api/common/fetchManagerUsers';
import DeleteButton from '../../common/DeleteButton';
import Popup from '../../common/popup/Popup';
import { deleteCalendarEvent } from '../../../../api/calendar/deleteCalendarEvent';

const CalendarCreateEdit: React.FC = () => {
  const [isAccessable, setIsAccessable] = useState<boolean>(false);
  const user = useCurrentUserInfo();
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [subscriptonExpired] = useState<boolean>(isSubscriptionExpired(selectedAccount));
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const current_page = useLocation();
  const current_route = current_page['pathname'];
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  let sourceId = queryParams.get('source');
  const encryptedSourceId = sourceId; // Decrypt the ID
  sourceId = decryptData(sourceId, true);
  const source_Id = parseInt(sourceId!, 10);
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { setNotificationSuccess } = useNotificationContext();
  const [isDisabled, setIsDisabled] = useState(Boolean);
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [userList, setUserList] = useState<DropdownResponse[]>([]);
  const mainPath = `/compliance-calendar/${encryptedSourceId}`;
  const [eventAddMode, setEventAddMode] = useState(false);
  const { moduleRecords } = useModuleContext();
  const [showRecurringFields, setShowRecurringFields] = useState(false);
  const [firstRadioChecked, setFirstRadioChecked] = useState(true);
  const [secondRadioChecked, setSecondRadioChecked] = useState(false);
  const [createTask, setCreateTask] = useState(false);
  const [inputsDisabled, setInputsDisabled] = useState({
    firstContainer: false,
    secondContainer: false
  });

  const [openConfirmationPopup, setOpenConfirmationPopup] = useState<boolean>(false);

  const colorOptions = [
    { label: 'F0BA0A', value: '#F0BA0A' },
    { label: 'EE7C0E', value: '#EE7C0E' },
    { label: 'A23B3C', value: '#A23B3C' },
    { label: '800080', value: '#800080' },
    { label: '6950A1', value: '#6950A1' },
    { label: '0053A1', value: '#0053A1' },
    { label: '7BA0A0', value: '#7BA0A0' },
    { label: '006E46', value: '#006E46' }
    // { label: '666666', value: '#666666' }
  ];

  const handleRadioChange = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors((error: any) => ({
      ...error,
      pattern_dayName: '',
      day: '',
      month: '',
      monthNumber: '',
      pattern_dayNumber: '',
      pattern_month: '',
      pattern_monthNumber: ''
    }));

    if (type === '1') {
      setFirstRadioChecked(event.target.checked);
      setSecondRadioChecked(!event.target.checked);
      setInputsDisabled({
        firstContainer: !event.target.checked,
        secondContainer: event.target.checked
      });
      recurrence_rule = '';
      setEvent((prevEvent) => ({
        ...prevEvent,
        pattern_dayNumber: '',
        pattern_dayName: '',
        pattern_monthNumber: '',
        pattern_month: ''
      }));
      setFormData({ ...formData, recurrence_type: 0 });
    } else {
      setFirstRadioChecked(!event.target.checked);
      setSecondRadioChecked(event.target.checked);
      setInputsDisabled({
        firstContainer: event.target.checked,
        secondContainer: !event.target.checked
      });
      recurrence_rule = '';
      setFormData({ ...formData, recurrence_type: 1 });

      setEvent((prevEvent) => ({
        ...prevEvent,
        day: '',
        month: ''
      }));
    }
  };

  let secondTitle;
  if (sourceId && moduleRecords) {
    const moduleId = parseInt(sourceId, 10);
    const foundRegister =
      Array.isArray(moduleRecords) && moduleRecords.length > 0
        ? moduleRecords.find((module) => module.id === moduleId)
        : '';
    secondTitle = foundRegister ? foundRegister?.name : '';
  }

  const pageTitle = id ? `Compliance Calendar Details` : `Add ${secondTitle}`;
  const metapageTitle = `${process.env.REACT_APP_NAME}: ${pageTitle}`;

  const [formData, setFormData] = useState<CalendarFormData>({
    id: 0,
    module_id: parseInt(sourceId!, 10),
    subject: '',
    required_action: '',
    regulatory_reference: '',
    unique_id: '',
    color: '',
    event_date: '',
    is_recurring: 'no',
    recurrence_type: 0,
    create_task: 0,
    task_days: 0,
    is_open: 1,
    owner_id: '',
    owner: {
      id: 0,
      email: '',
      first_name: '',
      last_name: ''
    }
  });

  const [event, setEvent] = useState<CalendarEventData>({
    recurrence_pattern: 'MONTHLY',
    day: '',
    month: '',
    monthNumber: '',
    pattern_dayNumber: '',
    pattern_dayName: '',
    pattern_monthNumber: '',
    pattern_month: ''
  });

  useEffect(() => {
    const permission = current_route === `/calendar/${encryptedId}` ? READ : id ? UPDATE : CREATE;
    // for check this page permission
    if (subscriptonExpired || !checkUserPermission(user, MODULES, source_Id, permission)) {
      navigate('/dashboard');
    }

    setIsAccessable(true);
  }, []);

  const HandlesnapshotIsDisabled = () => {
    if (isDisabled) {
      setIsDisabled(false);
      navigate(`/calendar-event-edit/${encryptedId}?source=${encryptedSourceId}`);
    }
  };

  const fetchUsers = async () => {
    try {
      const response: DropdownResponse[] = await fetchManagerUsers(authToken, selectedAccount);
      if (response) {
        setUserList(response);
      }
    } catch (error) {
      setErrors({});
      console.error('Error fetching templates:', error);
    }
  };

  useEffect(() => {
    if (
      current_route == `/calendar-event-edit/${encryptedId}` ||
      current_route == `/calendar-event-add`
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (!hasEffectRun) {
      setHasEffectRun(true);
      fetchData();
      fetchUsers();
    } else {
      return;
    }
  }, [formData, hasEffectRun, current_route]);

  useEffect(() => {
    if (formData.is_recurring === 'yes') {
      if (event.recurrence_pattern) {
        setShowRecurringFields(true);
      }
    } else {
      setShowRecurringFields(false);
      recurrence_rule = '';
    }

    if (formData.create_task == 1) {
      setCreateTask(true);
    } else {
      setCreateTask(false);
    }
  }, [formData.is_recurring, formData.create_task]);

  const handleInputChangeEvent = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setEvent((prevData) => ({ ...prevData, [name]: value }));
  };

  const daysCalculation = (pattern: string) => {
    let rCount = '100';
    if (pattern === 'WEEKLY') {
      rCount = '480';
    } else if (pattern === 'MONTHLY') {
      rCount = '120';
    } else {
      rCount = '20';
    }
    return rCount;
  };

  const recurrencePattern = event.recurrence_pattern;

  let recurrence_rule = '';
  if (
    formData.event_date &&
    formData.event_date !== 'Invalid date format' &&
    formData.event_date !== 'Invalid Date'
  ) {
    if (recurrencePattern && firstRadioChecked) {
      const count = daysCalculation(recurrencePattern);
      if (recurrencePattern === 'WEEKLY') {
        let day = '';
        if (event.day == '1') {
          day = 'MO';
        } else if (event.day == '2') {
          day = 'TU';
        } else if (event.day == '3') {
          day = 'WE';
        } else if (event.day == '4') {
          day = 'TH';
        } else if (event.day == '5') {
          day = 'FR';
        } else if (event.day == '6') {
          day = 'SA';
        } else if (event.day == '7') {
          day = 'SU';
        }
        recurrence_rule = `DTSTART:${format(new Date(formData.event_date), 'yyyyMMdd')}\nRRULE:FREQ=${recurrencePattern};INTERVAL=${event.month};BYDAY=${day};COUNT=${count}`;
      } else if (recurrencePattern === 'MONTHLY') {
        recurrence_rule = `DTSTART:${format(new Date(formData.event_date), 'yyyyMMdd')}\nRRULE:FREQ=${recurrencePattern};INTERVAL=${event.month};BYMONTHDAY=${event.day};COUNT=${count}`;
      } else if (recurrencePattern === 'YEARLY') {
        recurrence_rule = `DTSTART:${format(new Date(formData.event_date), 'yyyyMMdd')}\nRRULE:FREQ=${recurrencePattern};INTERVAL=${event.month};BYMONTHDAY=${event.day};BYMONTH=${event.monthNumber};COUNT=${count}`;
      }
    } else if (recurrencePattern && secondRadioChecked) {
      let patternDayName = event.pattern_dayName;
      if(event.pattern_dayName == 'ST'){
        patternDayName = 'SA';
      }
      const count = daysCalculation(recurrencePattern);
      if (recurrencePattern === 'WEEKLY') {
        recurrence_rule = `DTSTART:${format(new Date(formData.event_date), 'yyyyMMdd')}\nRRULE:FREQ=${recurrencePattern};INTERVAL=${event.pattern_month};BYDAY=+${event.pattern_dayNumber}${patternDayName};COUNT=${count}`;
      } else if (recurrencePattern === 'MONTHLY') {
        recurrence_rule = `DTSTART:${format(new Date(formData.event_date), 'yyyyMMdd')}\nRRULE:FREQ=${recurrencePattern};INTERVAL=${event.pattern_month};BYDAY=+${event.pattern_dayNumber}${patternDayName};COUNT=${count}`;
      } else if (recurrencePattern === 'YEARLY') {
        recurrence_rule = `DTSTART:${format(new Date(formData.event_date), 'yyyyMMdd')}\nRRULE:FREQ=${recurrencePattern};INTERVAL=${event.pattern_month};BYDAY=+${event.pattern_dayNumber}${patternDayName};BYMONTH=${event.pattern_monthNumber};COUNT=${count}`;
      }
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeData = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setFormData({ ...formData, is_recurring: value });
    setShowRecurringFields(true);
  };

  const handleDropdown = (event: React.ChangeEvent<{ name: string; value: unknown }>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleEventDropdown = (event: React.ChangeEvent<{ name: string; value: unknown }>) => {
    const { name, value } = event.target;
    setEvent((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!formData.subject) {
      newErrors.subject = t('error.field.subjectRequired');
    }
    if (
      !formData.event_date ||
      formData.event_date == 'Invalid date format' ||
      formData.event_date == 'Invalid Date'
    ) {
      newErrors.event_date = t('error.field.eventDateRequired');
    }

    if (!formData.required_action) {
      newErrors.required_action = t('error.field.requiredActionRequired');
    }
    if (!formData.is_recurring) {
      newErrors.is_recurring = t('error.field.isRecurringRequired');
    }
    if (!formData.owner_id) {
      newErrors.owner_id = t('error.field.ownerRequired');
    }

    if (formData.create_task === 1) {
      if (!formData.task_days) {
        newErrors.task_days = t('error.field.taskRequired');
      }
    }
    if (!event.recurrence_pattern) {
      newErrors.recurrence_pattern = t('error.field.recurrencePatternRequired');
    }

    if (formData.is_recurring === 'yes') {
      if (firstRadioChecked && !event.day) {
        newErrors.day = t('error.field.weekDayRequired');
      }

      if (firstRadioChecked && !event.month) {
        newErrors.month = t('error.field.weekMonthRequired');
      }

      if (firstRadioChecked && event.recurrence_pattern === 'YEARLY' && !event.monthNumber) {
        newErrors.monthNumber = t('error.field.recurrencePatternMonthNumberRequired');
      }

      if (secondRadioChecked && !event.pattern_dayName) {
        newErrors.pattern_dayName = ' ';
      }
      if (secondRadioChecked && !event.pattern_dayNumber) {
        newErrors.pattern_dayNumber = ' ';
      }
      if (secondRadioChecked && !event.pattern_month) {
        newErrors.pattern_month = ' ';
      }

      if (
        secondRadioChecked &&
        event.recurrence_pattern === 'YEARLY' &&
        !event.pattern_monthNumber
      ) {
        newErrors.pattern_monthNumber = t('error.field.recurrencePatternMonthNumberRequired');
      }
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const fetchData = async () => {
    if (id && authToken) {
      try {
        const moduleId = parseInt(id!, 10);

        const response: CalenderEventState = (await getCalendarEventId(
          source_Id,
          authToken,
          selectedAccount,
          moduleId
        )) as CalenderEventState;

        if (response) {
          const {
            id,
            subject,
            task_days,
            create_task,
            required_action,
            regulatory_reference,
            color,
            event_date,
            is_recurring,
            recurrence_type,
            owner_id,
            created_at,
            module_id,
            is_open,
            owner,
            unique_id,
            recurrence_rule
          } = response;
          const newFormData = {
            id,
            subject,
            task_days,
            create_task,
            required_action,
            regulatory_reference,
            color,
            event_date,
            is_recurring,
            recurrence_type,
            owner_id,
            created_at: created_at || '',
            is_open,
            module_id,
            unique_id,
            owner: {
              id: owner ? owner.id : 0,
              email: owner ? owner.email : '',
              first_name: owner ? owner.first_name : '',
              last_name: owner ? owner.last_name : ''
            }
          };
          if (recurrence_rule) {
            setEvent((prevEvent) => ({
              ...prevEvent,
              recurrence_rule: recurrence_rule
            }));
          }
          setFormData(newFormData);
          if (is_recurring === 'yes' && recurrence_rule) {
            setShowRecurringFields(true);
            const rule = RRule.fromString(recurrence_rule);
            setEvent((prevEvent) => ({
              ...prevEvent,
              recurrence_pattern:
                rule.options.freq != null ? mapFrequencyToString(rule.options.freq) : 1
            }));
            if (recurrence_type === 0) {
              setFirstRadioChecked(true);
              setSecondRadioChecked(false);
              setInputsDisabled({
                firstContainer: false,
                secondContainer: true
              });
              const bymonthday = rule.options.bymonthday ? rule.options.bymonthday.toString() : '';
              const byweekday = rule.options.byweekday ? rule.options.byweekday.toString() : '';
              setEvent((prevEvent) => ({
                ...prevEvent,
                month: rule.options.interval ? rule.options.interval.toString() : '',
                monthNumber: rule.options.bymonth ? rule.options.bymonth.toString() : '',
                day: bymonthday ? bymonthday : (parseInt(byweekday) + 1).toString() 
              }));
            } else {
              setSecondRadioChecked(true);
              setFirstRadioChecked(false);
              setInputsDisabled({
                firstContainer: true,
                secondContainer: false
              });
              const byDayMatch = recurrence_rule.match(/BYDAY=([+-]?\d*)([A-Za-z]{2})/);

              let occurrenceNumber: number;
              let dayAbbreviation: string;
              if (byDayMatch) {
                occurrenceNumber = parseInt(byDayMatch[1].toString(), 10); // Extracted occurrence number
                dayAbbreviation = byDayMatch[2]; // Extracted day abbreviation
              }

              setEvent((prevEvent) => ({
                ...prevEvent,
                pattern_dayNumber: occurrenceNumber ? occurrenceNumber.toString() : '',
                pattern_dayName: dayAbbreviation,
                pattern_month: rule.options.interval ? rule.options.interval.toString() : '',
                pattern_monthNumber: rule.options.bymonth ? rule.options.bymonth.toString() : ''
              }));
              if (createTask) {
                setCreateTask(true);
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
        // Handle any errors here
        setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
      }
    }
  };

  const handleFormSubmit = async (is_open: number) => {
    let isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }
    if (!formData.id) {
      isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
    }

    const recurrenceEventDates: any = [];
    if (recurrence_rule) {
      const rule = RRule.fromString(recurrence_rule);
      const occurrences = rule.all();
      occurrences.map((rule: any) => {
        const dateObject = new Date(rule);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so January is 0
        const year = dateObject.getFullYear();
        const date = year + '-' + month + '-' + day;
        recurrenceEventDates.push(date);
      });
    }
    const requestData = {
      module_id: formData.module_id,
      subject: formData.subject,
      owner_id: formData.owner_id,
      required_action: formData.required_action,
      regulatory_reference: formData.regulatory_reference,
      color: formData.color,
      event_date: formData.event_date,
      is_recurring: formData.is_recurring,
      recurrence_type: formData.recurrence_type,
      task_days: formData.task_days,
      create_task: formData.create_task,
      recurrence_rule: recurrence_rule,
      recurring_event_dates: recurrenceEventDates,
      is_open: is_open
    };
    const edit_id = id ? id : formData.id;
    try {
      const response: any = await createCalendarData(
        requestData,
        authToken,
        selectedAccount,
        edit_id
      );

      if (response && response?.id) {
        const msg = id ? t('success.field.eventUpdated') : t('success.field.eventCreated');

        if (
          response.id &&
          current_route &&
          current_route === `/calendar-event-add` &&
          !eventAddMode
        ) {
          setErrors({});
          navigate(
            `/calendar/${encryptData(String(response.id), true)}?source=${encryptedSourceId}`
          );
        } else if (id || eventAddMode) {
          setHasEffectRun(false);
          setNotificationSuccess(msg);
          setEventAddMode(false);
          navigate(`/calendar/${encryptedId}?source=${encryptedSourceId}`);
        } else {
          navigate(`/calendar/${encryptedSourceId}`);
          setNotificationSuccess(msg);
        }
      } else if (response == t('permission.doesNotHaveAccessPermission')) {
        setFormData((prevData) => ({
          ...prevData,
          error: t('permission.doesNotHaveAccessPermission')
        }));
      } else {
        // Error handling for validation errors
        const newErrors: Record<string, string> = {};

        if (response.required_action) {
          newErrors.required_action = response.required_action[0];
        } else if (response.owner_id) {
          newErrors.owner_id = response.owner_id[0];
        } else if (response.subject) {
          newErrors.subject = response.subject[0];
        } else if (response.regulatory_reference) {
          newErrors.regulatory_reference = response.regulatory_reference[0];
        } else if (response.color) {
          newErrors.color = response.color[0];
        } else if (response.event_date) {
          newErrors.event_date = response.event_date[0];
        } else if (response.is_recurring) {
          newErrors.is_recurring = response.is_recurring[0];
        } else if (response.task_days) {
          newErrors.task_days = response.task_days[0];
        } else if (response.create_task) {
          newErrors.create_task = response.create_task[0];
        } else {
          setFormData((prevData) => ({ ...prevData, error: t('somethingWentWrong') }));
        }

        setErrors(newErrors);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelClick = () => {
    if (id) {
      navigate(`/calendar/${encryptedId}?source=${encryptedSourceId}`);
    } else {
      navigate(`/compliance-calendar/${encryptedSourceId}`);
    }
  };

  const handleComplete = () => {
    handleFormSubmit(0);
  };

  const togglePopup = () => {
    setOpenConfirmationPopup(false);
  };

  const handleConfirmation = async () => {
    setOpenConfirmationPopup(false);

    try {
      if (id && authToken) {
        const response: any = (await deleteCalendarEvent(parseInt(id!, 10), authToken)) as any;

        if (response.id) {
          setNotificationSuccess(t('success.field.eventDeleted'));
          navigate(`/compliance-calendar/${sourceId}`);
        } else if (response == t('permission.doesNotHaveAccessPermission')) {
          setFormData((prevData) => ({
            ...prevData,
            error: t('permission.doesNotHaveAccessPermission')
          }));
        } else {
          console.log(response);
        }
      }
    } catch (error: any) {
      console.log('Error: ', error);
    }
  };

  let edit_btn_snapshot = null;
  if (formData.is_open === 1) {
    edit_btn_snapshot = (
      <>
        {checkUserPermission(user, MODULES, source_Id, UPDATE) && (
          <Button onClick={() => HandlesnapshotIsDisabled()} className="btn blue-fill-btn">
            <i className="left">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                <g clipPath="url(#clip0_6545_879)">
                  <path
                    d="M12.1666 1.89063V5.33274C12.1666 5.79945 12.1666 6.03281 12.2574 6.21107C12.3373 6.36787 12.4648 6.49535 12.6216 6.57525C12.7999 6.66608 13.0332 6.66608 13.4999 6.66608H16.942M12.1666 14.166H7.16659M13.8333 10.8327H7.16659M17.1666 8.32287V14.3327C17.1666 15.7328 17.1666 16.4329 16.8941 16.9677C16.6544 17.4381 16.272 17.8205 15.8016 18.0602C15.2668 18.3327 14.5667 18.3327 13.1666 18.3327H7.83325C6.43312 18.3327 5.73306 18.3327 5.19828 18.0602C4.72787 17.8205 4.34542 17.4381 4.10574 16.9677C3.83325 16.4329 3.83325 15.7328 3.83325 14.3327V5.66602C3.83325 4.26588 3.83325 3.56582 4.10574 3.03104C4.34542 2.56063 4.72787 2.17818 5.19828 1.9385C5.73306 1.66602 6.43312 1.66602 7.83325 1.66602H10.5097C11.1212 1.66602 11.4269 1.66602 11.7147 1.73509C11.9698 1.79633 12.2136 1.89734 12.4373 2.03442C12.6896 2.18902 12.9058 2.40521 13.3382 2.83759L15.995 5.49444C16.4274 5.92682 16.6436 6.14301 16.7982 6.3953C16.9353 6.61898 17.0363 6.86285 17.0975 7.11794C17.1666 7.40566 17.1666 7.71139 17.1666 8.32287Z"
                    stroke="CurrentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6545_879">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </i>
            Edit
          </Button>
        )}
      </>
    );
  }

  let addButton;
  if (formData && formData.is_open === 1) {
    addButton = (
      <div className="inner-btn-wrap">
        {isDisabled && current_route === `/calendar/${encryptedId}` && (
          <React.Fragment>
            {checkUserPermission(user, MODULES, source_Id, NOTE_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/calendar/add-note?source=${encryptedSourceId}&moduleId=${encryptedId}`}>
                <i className="left">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M1.08325 17.9176L5.70764 16.139C6.00343 16.0252 6.15132 15.9683 6.28968 15.894C6.41259 15.8281 6.52975 15.7519 6.63995 15.6664C6.76402 15.5701 6.87606 15.4581 7.10015 15.234L16.4999 5.83424C17.4204 4.91376 17.4204 3.42138 16.4999 2.5009C15.5795 1.58043 14.0871 1.58043 13.1666 2.5009L3.76682 11.9007C3.54273 12.1248 3.43069 12.2368 3.33441 12.3609C3.2489 12.4711 3.17275 12.5882 3.10678 12.7111C3.03251 12.8495 2.97563 12.9974 2.86186 13.2932L1.08325 17.9176ZM1.08325 17.9176L2.79835 13.4584C2.92108 13.1393 2.98245 12.9797 3.0877 12.9066C3.17969 12.8428 3.2935 12.8186 3.4035 12.8396C3.52936 12.8637 3.65024 12.9845 3.89199 13.2263L5.77456 15.1089C6.01631 15.3506 6.13719 15.4715 6.16122 15.5973C6.18223 15.7073 6.15807 15.8212 6.09421 15.9131C6.02112 16.0184 5.86158 16.0798 5.54248 16.2025L1.08325 17.9176Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Note
              </Button>
            )}

            {checkUserPermission(user, MODULES, source_Id, TASK_CREATE) && (
              <Button
                className="btn blue-fill-btn"
                component={RouterLink}
                to={`/calendar/add-task?source=${encryptedSourceId}&moduleId=${encryptedId}`}>
                <i className="left">
                  <svg width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <g clipPath="url(#clip0_6545_879)">
                      <path
                        d="M11.3333 3.33268C12.1082 3.33268 12.4957 3.33268 12.8136 3.41787C13.6764 3.64904 14.3502 4.3229 14.5814 5.18564C14.6666 5.50355 14.6666 5.89104 14.6666 6.66602V14.3327C14.6666 15.7328 14.6666 16.4329 14.3941 16.9677C14.1544 17.4381 13.772 17.8205 13.3016 18.0602C12.7668 18.3327 12.0667 18.3327 10.6666 18.3327H5.33325C3.93312 18.3327 3.23305 18.3327 2.69828 18.0602C2.22787 17.8205 1.84542 17.4381 1.60574 16.9677C1.33325 16.4329 1.33325 15.7328 1.33325 14.3327V6.66602C1.33325 5.89104 1.33325 5.50355 1.41844 5.18564C1.64961 4.3229 2.32347 3.64904 3.1862 3.41787C3.50412 3.33268 3.89161 3.33268 4.66659 3.33268M5.49992 12.4993L7.16659 14.166L10.9166 10.416M5.99992 4.99935H9.99992C10.4666 4.99935 10.7 4.99935 10.8782 4.90852C11.035 4.82863 11.1625 4.70114 11.2424 4.54434C11.3333 4.36608 11.3333 4.13273 11.3333 3.66602V2.99935C11.3333 2.53264 11.3333 2.29928 11.2424 2.12102C11.1625 1.96422 11.035 1.83674 10.8782 1.75684C10.7 1.66602 10.4666 1.66602 9.99992 1.66602H5.99992C5.53321 1.66602 5.29985 1.66602 5.12159 1.75684C4.96479 1.83674 4.83731 1.96422 4.75741 2.12102C4.66659 2.29928 4.66659 2.53264 4.66659 2.99935V3.66602C4.66659 4.13273 4.66659 4.36608 4.75741 4.54434C4.83731 4.70114 4.96479 4.82863 5.12159 4.90852C5.29985 4.99935 5.53321 4.99935 5.99992 4.99935Z"
                        stroke="CurrentColor"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6545_879">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                Add Task
              </Button>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  return (
    <>
      {isAccessable && (
        <CommonLayout title={metapageTitle}>
          <section className="record-section settings-page-wrap">
            <div className="container-full">
              <CommonBreadcrumbs
                mainPath={mainPath}
                title={formData.unique_id}
                pageTitle={pageTitle}
                secondTitle={secondTitle}
                secondpath={mainPath}
                button={id ? addButton : undefined}
              />
              <div className="record-outer-row settings-outer-row">
                <CalenderLeftMenu
                  user={user}
                  moduleId={id}
                  sourceId={sourceId ? sourceId : ''}
                  formData_record={formData}
                  calendarIdEncrypted={encryptedId}
                  sourceIdEncrypted={encryptedSourceId}
                />
                <div className="right-outer">
                  <div className="right-inner">
                    {current_route &&
                      (current_route === `/calendar-event-edit/${encryptedId}` ||
                        current_route === `/calendar/${encryptedId}` ||
                        current_route === `/calendar-event-add`) &&
                      !eventAddMode && (
                        <CalendarCreateEditFormData
                          formData={formData}
                          errors={errors}
                          userList={userList}
                          isDisabled={isDisabled}
                          current_route={current_route}
                          eventAddMode={eventAddMode}
                          edit_btn_snapshot={edit_btn_snapshot}
                          event={event}
                          showRecurringFields={showRecurringFields}
                          handleInputChangeEvent={handleInputChangeEvent}
                          handleChangeData={handleChangeData}
                          handleDropdown={handleDropdown}
                          handleInputChange={handleInputChange}
                          setFormData={setFormData}
                          handleComplete={handleComplete}
                          handleEventDropdown={handleEventDropdown}
                          firstRadioChecked={firstRadioChecked}
                          secondRadioChecked={secondRadioChecked}
                          createTask={createTask}
                          inputsDisabled={inputsDisabled}
                          handleRadioChange={handleRadioChange}
                          colorOptions={colorOptions}
                          calendarIdEncrypted={encryptedId}
                          sourceIdEncrypted={encryptedSourceId}
                        />
                      )}
                    {!isDisabled &&
                      !eventAddMode &&
                      (current_route == `/calendar-event-add` ||
                        current_route == `/calendar-event-edit/${encryptedId}`) && (
                        <div className="btn-wrap">
                          <Button className="btn border-btn btn-sm" onClick={handleCancelClick}>
                            Cancel
                          </Button>
                          <Button
                            className="btn primary-btn btn-sm"
                            type="submit"
                            onClick={() => handleFormSubmit(1)}>
                            Save
                          </Button>
                        </div>
                      )}

                    {checkUserPermission(user, MODULES, source_Id, DELETE) &&
                      current_route === `/calendar/${encryptedId}` && (
                        <DeleteButton setOpenConfirmationPopup={setOpenConfirmationPopup} />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Popup
            type="confirmation"
            openPopup={openConfirmationPopup}
            text={`Are you sure you want to delete this record?`}
            togglePopup={togglePopup}
            handleConfirmation={handleConfirmation}
          />
        </CommonLayout>
      )}
    </>
  );
};

export default CalendarCreateEdit;
