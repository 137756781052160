import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import userIc from '../../../assets/images/user-ic.svg';
import userActive from '../../../assets/images/user-black-ic.svg';
import accountDetail from '../../../assets/images/account-details-ic.svg';
import accountDetailActive from '../../../assets/images/account-details-black-ic.svg';
import modulesIc from '../../../assets/images/modules-ic.svg';
import modulesActive from '../../../assets/images/modules-black-ic.svg';
import registerIc from '../../../assets/images/registers-ic.svg';
import registerActive from '../../../assets/images/registers-black-ic.svg';
import updateIc from '../../../assets/images/updates-Icon.svg';
import policies from '../../../assets/images/policies.svg';
import policiesLight from '../../../assets/images/policieslight.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useTranslation } from 'react-i18next';
import {
  checkUserPermission,
  useCurrentUserInfo,
  decryptData,
  getSelectedAccountInfo
} from '../../../utils/common/user';
import { SETTINGS } from '../../../constants/constants';
import { PlanLevel } from '../../../utils/common/variable';

const LeftNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams(); // Get encrypted ID from URL
  const encryptedId = id; // Decrypt the ID
  id = id ? decryptData(id, true) : 0;
  const { t } = useTranslation();
  const currentUrl = location.pathname;
  const user = useCurrentUserInfo();
  const selectedAccount = getSelectedAccountInfo();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string) => {
    // if (path !== '/settings/billing') {
      navigate(path);
    // } else {
    //   const newWindow = window.open(path, '_blank', 'noopener,noreferrer');
    //   if (newWindow) newWindow.opener = null;
    // }
  };

  let menuItems = [
    {
      id: 1,
      icon: userIc,
      iconActive: userActive,
      label: 'User Access Permission',
      path: ['/settings/user-access-permisssion']
    },
    {
      id: 2,
      icon: userIc,
      iconActive: userActive,
      label: 'Users',
      path: ['/settings/user']
    },
    {
      id: 3,
      icon: accountDetail,
      iconActive: accountDetailActive,
      label: 'Account Details',
      path: ['/settings/account-details']
    },
    {
      id: 4,
      icon: modulesIc,
      iconActive: modulesActive,
      label: 'Modules',
      path: [
        '/settings/modules',
        `/settings/modules/file-review-templates/${encryptedId}`,
        `/settings/modules/adviser-review/${encryptedId}`,
        `/settings/modules/meeting/${encryptedId}`,
        `/settings/modules/compliance-calender/${encryptedId}`,
        `/settings/modules/onboarding/${encryptedId}`,
        `/settings/modules/pre-vet/${encryptedId}`,
        `/settings/modules/adviser-profile/${encryptedId}`,
        `/settings/modules/attestations/${encryptedId}`,
        `/settings/modules/professional-year/${encryptedId}`
      ]
    },
    {
      id: 5,
      icon: registerIc,
      iconActive: registerActive,
      label: 'Registers',
      path: ['/settings/registers', `/settings/registers/provider/${encryptedId}`]
    },
    {
      id: 6,
      icon: updateIc,
      iconActive: updateIc,
      label: 'Updates',
      path: ['/settings/updates', '/settings/updates-add', `/settings/updates-edit/${encryptedId}`]
    },
    {
      id: 7,
      icon: policiesLight,
      iconActive: policies,
      label: 'Policies',
      path: [
        '/settings/policies',
        '/settings/policies/add',
        `/settings/policies/update/${encryptedId}`,
        `/settings/policies/details/${encryptedId}`
      ]
    },
    // {
    //   id: 8,
    //   icon: policiesLight,
    //   iconActive: policies,
    //   label: 'Billing',
    //   path: ['/settings/billing']
    // },
    {
      id: 9,
      icon: userIc,
      iconActive: userActive,
      label: 'Account Management',
      path: ['/settings/add-accounts']
    },
    {
      id: 10,
      icon: policiesLight,
      iconActive: policies,
      label: 'Plans',
      path: ['/settings/plans']
    }
  ];

  menuItems = menuItems.filter((item) => {
    if (item.label === 'Billing' && selectedAccount.stripe_customer_id && !PlanLevel.includes(selectedAccount.plan_level)) {
      return checkUserPermission(user, SETTINGS, item.id);
    } else if (item.label !== 'Billing') {
      return checkUserPermission(user, SETTINGS, item.id);
    }
  });

  return (
    <div className={`settings-sidebar-wrap ${sidebarOpen ? 'open' : ''}`}>
      <div className="overlay" onClick={toggleSidebar}></div>
      <IconButton onClick={toggleSidebar} aria-label="hamburger" className="toggle-button">
        <MenuRoundedIcon />
      </IconButton>

      <nav className="settings-sidebar">
        <div className="close-btn-wrap">
          <IconButton className="close-btn" onClick={toggleSidebar}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="sidebar-inner">
          {location.pathname === '/settings/add-user' ? (
            <span>{t('setting.addUserMsg')}</span>
          ) : location.pathname === `/settings/edit-user/${encryptedId}` ? (
            <span>{t('setting.updateUserMsg')}</span>
          ) : location.pathname === `/settings/modules-settings/${encryptedId}` ? (
            <span></span>
          ) : location.pathname === `/settings/modules-settings/${encryptedId}` ? (
            <span></span>
          ) : (
            <ul className="menu-item-wrap">
              {menuItems.map((item) => (
                <li
                  key={item.id}
                  className={`menu-item ${
                    Array.isArray(item.path) && item.path.includes(currentUrl) ? 'active' : ''
                  }`}
                  onClick={() => {
                    if (Array.isArray(item.path)) {
                      handleNavigation(item.path[0]);
                    }
                  }}>
                  <i className="icon">
                    <img
                      src={`${
                        Array.isArray(item.path) && item.path.includes(location.pathname)
                          ? item.iconActive
                          : item.icon
                      }`}
                    />
                  </i>
                  <span>{item.label}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </nav>
    </div>
  );
};

export default LeftNav;
