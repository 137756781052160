import { Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import { TasksState } from '../reporting/state';
import { encryptData, getSelectedAccountInfo, useAuthToken } from '../../../utils/common/user';
import arrowRight from '../../../assets/images/arrow-circle-right.svg';
import { PaginationResponse } from '../user/state';
import { fetchTasks } from '../../../api/reporting/fetchReportingTasks';
import uploadPremium from '../../../assets/images/3Lines-Upgrade-Premium.png';

const TaskColumn = () => {
  const navigate = useNavigate();
  const authToken = useAuthToken();
  const selectedAccount = getSelectedAccountInfo();
  const [loading, setLoading] = useState<boolean>(true);
  const [topTasks, setTopTasks] = useState<TasksState[]>([]);
  const [tasks, setTasks] = useState<TasksState[]>([]);

  const UrlAsModuleType = (type: string, taskId: number, parentId: number, sourceId: number) => {
    let url;
    taskId = encryptData(String(taskId), true);
    sourceId = encryptData(String(sourceId), true);
    parentId = encryptData(String(parentId), true);
    if (type === 'register_records') {
      url = `/register/edit-task/${taskId}?source=${sourceId}&recordId=${parentId}`;
    } else if (type === 'reviews') {
      url = `/review/edit-task/${taskId}?source=${sourceId}&reviewId=${parentId}`;
    } else if (type === 'calendar_events') {
      url = `/calendar/edit-task/${taskId}?source=${sourceId}&moduleId=${parentId}`;
    } else if (type === 'meetings') {
      url = `/meeting/edit-task/${taskId}?source=${sourceId}&meetingId=${parentId}`;
    } else if (type === 'onboardings') {
      url = `/onboarding/edit-task/${taskId}?source=${sourceId}&onboardingId=${parentId}`;
    } else if (type === 'providers') {
      url = `/providers/edit-task/${taskId}?source=${sourceId}&providerId=${parentId}`;
    } else if (type === 'prevets') {
      url = `/prevet/edit-task/${taskId}?source=${sourceId}&prevetId=${parentId}`;
    } else if (type === 'adviser-profile') {
      url = `/adviser-profile/edit-task/${taskId}?source=${sourceId}&adviserProfileId=${parentId}`;
    } else if (type === 'professional_years') {
      url = `/professional-year/edit-task/${taskId}?source=${sourceId}&professionalYearId=${parentId}`;
    }

    return url;
  };

  const fetchAllTasks = async () => {
    try {
      if (authToken) {
        const response: PaginationResponse = (await fetchTasks(
          authToken,
          selectedAccount,
          1,
          10,
          2,
          '',
          '0'
        )) as PaginationResponse;
        if (response && response.data) {
          if (Array.isArray(response.data)) {
            setTasks(response.data);
            setTopTasks(response.data.slice(0, 5));
          }
        }
      }
    } catch (error) {
      // Handle any errors here
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllTasks();
  }, []);

  const handleEditTask = (url: string) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className="dashboard-left-col">
      {selectedAccount && selectedAccount.plan_level === '0' && (
        <div className="upload-premium-blog">
          <Button component={RouterLink} to={`/user-profile`}>
            <img src={uploadPremium} alt="Upload Premium" />
          </Button>
        </div>
      )}

      <div className="task-outer">
        <h5>My tasks</h5>
        {loading ? ( // Check loading state
          <div className="no-record">
            <CircularProgress />
          </div>
        ) : tasks.length === 0 ? (
          <div className="no-tasks mb-2">You have no open tasks</div>
        ) : (
          <div className="task-inner">
            {topTasks.map((row: TasksState) => {
              const url = UrlAsModuleType(row.module_type, row.id, row.module_id, row.source_id);
              return (
                row?.account_id == selectedAccount.id && (
                  <div className="task-list" key={row.id}>
                    <a className="task-wrapper">
                      <h6>{(row.task || '').replace(/<br\/>/g, '\n')}</h6>
                      <div className="task-des-wrapper">
                        <p>
                          Due: {row.date_due ? format(new Date(row.date_due), 'd/MMM/yyyy') : 'N/A'}
                        </p>
                        <span
                          onClick={() => handleEditTask(url ? url : '')}
                          className="edit-task-arrow-home">
                          <img src={arrowRight} alt="Arrow Right" />
                        </span>
                      </div>
                    </a>
                  </div>
                )
              );
            })}
          </div>
        )}
      </div>
      {tasks.length >= 1 && (
        <Button
          className="btn btn-sm blue-fill-btn mb-2"
          onClick={() => {
            navigate('/my-tasks');
          }}>
          <i className="left">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
              <path
                d="M13.8333 3.33366C14.6082 3.33366 14.9957 3.33366 15.3136 3.41884C16.1764 3.65001 16.8502 4.32388 17.0814 5.18661C17.1666 5.50453 17.1666 5.89202 17.1666 6.66699V14.3337C17.1666 15.7338 17.1666 16.4339 16.8941 16.9686C16.6544 17.439 16.272 17.8215 15.8016 18.0612C15.2668 18.3337 14.5667 18.3337 13.1666 18.3337H7.83325C6.43312 18.3337 5.73305 18.3337 5.19828 18.0612C4.72787 17.8215 4.34542 17.439 4.10574 16.9686C3.83325 16.4339 3.83325 15.7338 3.83325 14.3337V6.66699C3.83325 5.89202 3.83325 5.50453 3.91844 5.18661C4.14961 4.32388 4.82347 3.65001 5.6862 3.41884C6.00412 3.33366 6.39161 3.33366 7.16659 3.33366M8.49992 5.00033H12.4999C12.9666 5.00033 13.2 5.00033 13.3782 4.9095C13.535 4.8296 13.6625 4.70212 13.7424 4.54532C13.8333 4.36706 13.8333 4.1337 13.8333 3.66699V3.00033C13.8333 2.53362 13.8333 2.30026 13.7424 2.122C13.6625 1.9652 13.535 1.83771 13.3782 1.75782C13.2 1.66699 12.9666 1.66699 12.4999 1.66699H8.49992C8.03321 1.66699 7.79985 1.66699 7.62159 1.75782C7.46479 1.83771 7.33731 1.9652 7.25741 2.122C7.16659 2.30026 7.16659 2.53362 7.16659 3.00033V3.66699C7.16659 4.1337 7.16659 4.36706 7.25741 4.54532C7.33731 4.70212 7.46479 4.8296 7.62159 4.9095C7.79985 5.00033 8.03321 5.00033 8.49992 5.00033Z"
                stroke="CurrentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </i>
          View All Tasks
        </Button>
      )}
    </div>
  );
};

export default TaskColumn;
